import { default as index9KWdzpy7JOMeta } from "/var/www/sites/pickup.staging.ultrafedt.dk/releases/20241213094550/pages/index.vue?macro=true";
import { default as _91merchantId_93zTDaGIW6toMeta } from "/var/www/sites/pickup.staging.ultrafedt.dk/releases/20241213094550/pages/setup/[merchantId].vue?macro=true";
import { default as indexBnmNXWhsanMeta } from "/var/www/sites/pickup.staging.ultrafedt.dk/releases/20241213094550/pages/setup/index.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/var/www/sites/pickup.staging.ultrafedt.dk/releases/20241213094550/pages/index.vue")
  },
  {
    name: "setup-merchantId",
    path: "/setup/:merchantId()",
    component: () => import("/var/www/sites/pickup.staging.ultrafedt.dk/releases/20241213094550/pages/setup/[merchantId].vue")
  },
  {
    name: "setup",
    path: "/setup",
    component: () => import("/var/www/sites/pickup.staging.ultrafedt.dk/releases/20241213094550/pages/setup/index.vue")
  }
]
import { defineStore } from "pinia";

export const useAuth = defineStore("auth", {
  state: () => ({
    token: useCookie("pickup_screen_token", {
      maxAge: 60 * 60 * 24 * 7,
      sameSite: true,
    }),

    pickupScreen: null,
  }),

  actions: {
    async login(merchantId, pickupScreenId) {
      const runtimeConfig = useRuntimeConfig();

      const token = useCookie("jwt_token");

      const response = await $fetch(
        runtimeConfig.public.apiUrl +
          "/api/v1/merchant/pickup-screens/" +
          pickupScreenId +
          "/tokens",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            ContentType: "application/json",
            Authorization: "Bearer " + token.value,
            Merchant: merchantId,
          },
        },
      );

      this.token = response.data.attributes.value;

      token.value = null;

      await this.initialize();
    },

    async initialize() {
      const runtimeConfig = useRuntimeConfig();

      const response = await $fetch(
        runtimeConfig.public.apiUrl + "/api/v1/pickup/pickup-screen",
        {
          headers: {
            Accept: "application/json",
            ContentType: "application/json",
            Authorization: "Bearer " + this.token,
          },
        },
      );

      await this.setPickupScreen(response);

      setInterval(() => this.refresh(), 10 * 60 * 1000);
    },

    async refresh() {
      const runtimeConfig = useRuntimeConfig();

      try {
        const response = await $fetch(
          runtimeConfig.public.apiUrl + "/api/v1/auth/tokens/refresh",
          {
            method: "POST",
            headers: { Authorization: "Bearer " + this.token },
          },
        );

        this.token = response.data.attributes.value;
      } catch (error) {
        this.logout();
      }
    },

    async getMerchantAccesses() {
      const runtimeConfig = useRuntimeConfig();
      const token = useCookie("jwt_token");

      return await $fetch(
        runtimeConfig.public.apiUrl + "/api/v1/auth/merchant-accesses",
        {
          headers: {
            Accept: "application/json",
            ContentType: "application/json",
            Authorization: "Bearer " + token.value,
          },
        },
      ).then((response) => response.data);
    },

    async getPickupScreens(merchantId) {
      const runtimeConfig = useRuntimeConfig();
      const token = useCookie("jwt_token");

      return await $fetch(
        runtimeConfig.public.apiUrl + "/api/v1/merchant/pickup-screens",
        {
          headers: {
            Accept: "application/json",
            ContentType: "application/json",
            Authorization: "Bearer " + token.value,
            Merchant: merchantId,
          },
        },
      ).then((response) => response.data);
    },

    async updatePickupScreen(data) {
      const runtimeConfig = useRuntimeConfig();

      const response = await $fetch(
        runtimeConfig.public.apiUrl + "/api/v1/pickup/pickup-screen",
        {
          method: "PUT",
          body: data,
          headers: {
            Accept: "application/json",
            ContentType: "application/json",
            Authorization: "Bearer " + this.token,
          },
        },
      );

      await this.setPickupScreen(response);
    },

    async setPickupScreen(raw) {
      const { $i18n } = useNuxtApp();

      this.pickupScreen = raw.data;

      await $i18n.setLocale(raw.data.attributes.locale);
    },

    logout() {
      this.token = null;
    },
  },
});
